@media (min-width: 1200px) {

    .container {
        max-width: 1200px;
    }
   
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    
}

@media only screen and (max-width: 1199px) {
    
}

@media only screen and (max-width: 1024px) {
    
    .infText {
        right: -32px;
        left: auto;
        transform: unset;
    }

    .infText:after {
        left: auto;
        transform: rotate(45deg);
        right: 35px;
    }

}


@media only screen and (min-width: 768px) and (max-width: 991px) {

}

@media only screen and (max-width: 991px) {

}
    
@media only screen and (min-width: 426px) and (max-width: 767px) {
   
}

@media only screen and (min-width: 860px) {
    .mobileOnly {
        display: none !important;
    }
  
}
@media(max-width: 766px) {
    .sendNftTit {
        margin-top: -50px;
    }
    .navMenu{
        /* background-color: #232833;
        padding:  0 24px; */
        /* overflow: hidden; */
        border-radius: 20px;
        border: 1px solid #2D3342;
    }

    .navMenu .navMenu__link{
        width: 100%;
        background-color: #232833;
        padding: 23px;
        margin: 0 !important;
        
    }

    .nftConnect{
        
        display: flex;
        justify-content: center;
    }

    .navMenu__link:hover{
        background-color: #292E3B;
    }

    .nftConnect{
        padding-bottom: 24px;
    }
}
@media only screen and (max-width: 860px) {

    .mobileOnly.seleNftMob {
        display: flex;
        align-items: center;
        color: #62718A;
    }

    .mobileOnly.seleNftMob span {
        color: #297EFF;
        display: inline-block;
        margin-left: 5px;
    }

    .desktopOnly {
        display: none !important;
    }

    .col-lg-4.col-md-4.col-sm-6.col-6 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .nft_selectBox {
        padding-right: 16px;
        padding-left: 16px;
    }

    .nftListBox> .row> div {
        margin-bottom: 12px;
    }

  

    .selectNft {
        left: 12px;
        top: 12px;
    }

    .nftListBox> .row {
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: space-between;
    }
    
    span.nftImage img {
        width: 100%;
        height: auto;
    }
    .nftImageBox {
        width: 100%;
    }
    .span.nftImage img {
        width: 100%;
    }
    .nftName {
        width: 100% !important;
    }
    .nftListBox .col-lg-4.col-md-4.col-sm-6.col-6 {
        padding-right: 0px;
        padding-left: 0px;
        max-width: 48%;
    }
    .nftCont span :first-child{
        width: 100%;
    }
    .nftName {
        margin-top: 0px !important;
    }
    .nftCont  {
        padding-bottom: 0px;
        height: 78px;
    }
    .singleNft {
    }

    .desChain span {
        /* margin-left: 20px; */
        padding: 0px 20px;
        justify-content: space-between;
    }
    .desChain span img:nth-child(2) {
        margin-top: 3px !important;
        margin-left: 18px !important;
        margin-right: 2px !important;
    }
    .desChain span img {
        border-radius: 50%;
    }
    span.NFTInf img {
        width: 24px !important;
    }
    .destiReload {
        padding: 0px 18px;
    }
    .NFTInf {
        width: 24px !important;
    }
    .nftListBox {
        margin-right: -12px;
        padding-right: 12px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .aleartBox {
        width: 90vw;
    }

    /* .modal {
        padding: 0 !important;
    } */

    .nftWorng .modal-dialog,
    .ChainModal .modal-dialog,
    .NftDetails .modal-dialog {
        max-width: 343px;
        margin: 0 auto;
    }

    .nftDetailBox {
        display: block;
        height: 80vh;
        overflow: auto;
        max-height: 660px;
    }

    .nftDetailBox> div {
        width: 100%;
        padding: 0 20px;
        overflow: unset;
    }

    .nftDetImg {
        margin-bottom: 8px;
    }

    /* .modal-dialog {
        margin: 30px auto 0 auto;
    } */
   
    /* .nftSuccessMod .modal-dialog {
       transform: scale(0.8);
    } */

    .nftSuccessMod .modal-content {
        padding: 0 20px;
        /* border-radius: 0; */
    }

    .modal-header {
        min-height: 56px;
    }

    /* .nftSuccessMod .modal-header {
        margin-top: 20px;
    } */

    .transferTable.table thead th, .transferTable.table tr td {
        padding: 12px !important;
        white-space: nowrap;
    }

    .transferTable.table tbody tr td:first-child {
        min-width: 64px;
    }

    table.transferTable.table {
        min-width: 1020px;
    }

    .container {
        max-width: 100%;
    }

    .footLeft {
        margin-bottom: 30px;
    }

    .footRight {
        flex-direction: column;
        text-align: left;
        justify-content: flex-start;
    }

    .footContainer {
        flex-direction: column;
    }

    .priPoli a {
        margin: 0 22px 0 0;
    }

    .footRight> div {
        min-height: 46px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .powerBy {
        margin-bottom: 14px;
    }

    .nftTitle h2 {
        font-size: 28px;
    }

    .nftContainer {
        margin-top: 60px;
    }

    .HeaderArea {
        padding: 8px 16px;
    }

    .navbar {
        padding: 0 !important;
        align-items: center;
    }
    .navbar-light .navbar-toggler:after{
        margin-top: 21px;
        top: -13px;
        right: 0px;
        position: absolute;
    }
    .nftTopRIght {
        position: relative;
    }

    .nftListTop {
        flex-wrap: wrap;
    }

    .seleNftMob {
        width: 40%;
    }

    .nftTopRIght {
        width: 60%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
    }

    .mobSearch {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 20px;
    }
    .mobSearch img {
        min-width: 16px;
        margin-left: -4px;
    }
    .mobSearch form {
        display: contents;
    }

    .mobSearch input {
        display: block;
        flex-grow: 1;
        border: 0;
        background: rgba(98, 113, 138, 0.1);
        border-radius: 10px;
        height: 40px;
        font-size: 16px;
        line-height: 160%;
        padding: 0 40px 0 16px;
        color: #62718A;
        outline: none;
    }

    .mobSearch input::placeholder {
        color: rgb(98 113 138 / 50%);
    }

    .mobSearch button {
        position: absolute;
        right: 0;
        background: transparent;
        border: 0;
        outline: none;
        width: 40px;
        height: 40px;
    }

    .mobSearch input:focus {
        outline: 1px solid #174AFF;
    }

    .selectAll {
        background: rgba(98, 113, 138, 0.1);
        border-radius: 8px;
    }

    .nft_selectBox {
        height: auto;
    }

    .nftSendBtn {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .nftSendBtn a.themBtn {
        width: 100%;
        max-width: unset;
    }

    .nftselectedtop {
        justify-content: space-between !important;
    }

    .nftselectedtop .clearNft {
        margin: 0;
    }

    .nftselectedtop span.mobileOnly {
        font-weight: 600;
        font-size: 16px;
        line-height: 126%;
        color: #62718A;
    }

    .mtm50 {
        margin-top: 50px;
    }

    .sendNftBox {
        height: auto;
        min-height: unset;
    }

    .collecSlideCont .slideItem {
        padding: 0 11px;
    }
        
    .yourNft.seleNftMob.mobileOnly span {
        height: auto;
    }

    .navMenu.navbar-nav .nav-link {
        /* margin-left: 0;
        max-width: fit-content; */
    }

    .infText {
        right: -15px;
    }

    .infText:after {
        right: 18px;
    }


} /* max-width: 767px */
       
@media only screen and (max-width: 775px) {
    .accountBox {
        left: 50%;
        transform: translate(-50%, 0);
    }
    .singleNft{
            /* max-height: 160px !important; */
    }
}
    
@media only screen and (min-width: 320px) and (max-width: 425px) {
	
}    

@media only screen and (max-width: 480px) {

    .collecSlideCont.container {
        width: 250px;
    }

    .collecSlideCont .slick-list {
        overflow: visible;
    }
    
    .nonftContainer .row{
        gap: 10px;
    }
	
}
      
@media only screen and (max-width: 375px) {

}
   
