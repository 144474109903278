.nft-list__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
}

.nft-box__wrapper{
  position: relative;
}


.nft-box__container {
    margin-top: 1px;
    width: 224px;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.64px;
    /* border-radius: 2pt; */
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    overflow: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid transparent;
}

.brocken-url{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: #62718A;
}

.nftListed .brocken-url{
  flex-direction: row;
}

.nftListed .brocken-url img{
  width: 32px;
  height: 32px;
}

.brocken-url-selected{
  width: 32px;
  padding: 1px;
  border: 1px solid rgba(128, 128, 128, 0.411);
  border-radius: 5px;
}

.brocken-url__msg{
  text-align: center;
}

.preload__container{
    width: 224px;
    width: 100%;
    height: 345px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-border-radius: 20px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.preload__image{
    background-color: #1E222D;
    flex-grow: 4;
}
.preload-cont {
  pointer-events: none;
}
.preload__content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #292F3F;
    flex-grow: 1;
    pointer-events: none;
}
.preload-content-container {
  pointer-events: none;
}
.preload__name{
    height: 10px;
    width: 80%;
    background-color: #62718A;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.preload__number{
    height: 10px;
    width: 50%;
    background-color: #62718A;
    border-radius: 5px;
}

.image__wrapper img, .image__wrapper video {
    max-width: 80%;
    max-height: 240px;
    border-radius: 5px;
}

.nft-image__container {
    background-color: #1e222d;
    height: 244px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
}


.image__wrapper {
  display: flex;
  justify-content: center;
}

.nft-content__container {
  background-color: #292f3f;
  height: 100px;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.nft-box__container--selected {
    margin-top: 1px;
    width: 224px;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.64px;
    border-radius: 20px;
    -moz-border-border-radius: 20px;
    -webkit-border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    /* outline: solid 1px #3E64ED; */
    border: 1px solid #3E64ED;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.nft-box__container:hover{
  border: 1px solid #3e64ed8f;

}



.selected-radio {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 26px;
  height: 26px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.705);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.radio__container img{
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.705);
}

.nft-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nft-number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.missing{
  height: 100%;
}

@media screen and (max-width: 768px) {
  .nft-list__wrapper{
    gap: 8px;
  }
  .nft-box__wrapper{
    margin-left: 2px;
    width: calc(100% / 3.33);
  }
  .nft-box__container{
    max-width: 100%;

  }
  .nft-box__container--selected{
    max-width: 100%;

  }
  .missing-nft-box__wrapper{
    margin-left: 2px;
    width: calc(100% / 3.33);
  }
}

@media screen and (max-width: 600px){
  .nft-list__wrapper{
    gap: 10px;
  }
  .nft-box__wrapper {
    width: 47%;
  }
  .nft-box__container{
    width: 100%;
  }
  .nft-box__container--selected{
    width: 100%;
  }
  .missing-nft-box__wrapper{
    width: 47%;
  }
  .nft-box__wrapper:hover{
    outline: unset;
  }
}

@media screen and (max-width: 375px){
  .nft-list__wrapper{
    gap: 10px;
  }
  .nft-box__container{
    width: 90%;
    max-height: 240px;
  }
  .nft-box__container--selected{
    width: 90%;
    max-height: 240px;
  }
  .missing-nft-box__wrapper{
    width: 45%;

  }
  .preload__container{
    width: calc(100% - 15px);
    height: 240px !important;
  }
}

@media screen and (max-width: 425px) {
  .image__wrapper{
    max-height: calc(100% - 30px);
  }
  .nft-image__container{
    max-height: 170px;
  }
  .radio__container{
    
  }
  .preload__container{
    height: 270px;
  }
}
