.tn-process__modal{
    color: white;
}

.tn-process__modal .modal-dialog {
    max-width: 370px;
    margin: 30vh auto 0 auto;
}

.tn-process__header{

}

.tn-process__title{
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
}

.tn-process__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 60px; */
}

.tn-process__icon{
    min-height: 60px;
    min-width: 60px;
    margin: 30px 0 50px 0;
}

.tn-process__icon img{
    width: 100%;
}

.txt-icon{
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tn-process__message{
    font-size: 18px;
    font-weight: 4600;
    line-height: 21.78px;
    padding-bottom: 40px;
}

.tn-process__loader{
    display: flex;
    gap: 3.87px;
}

.process-loader--grey{
    width: 96px;
    height: 6px;
    background-color: #3A415A;
    border-radius: 2px;
    margin-bottom: 55px;
    position: relative;
}

.process-loader--green{
    position: absolute;
    height: 6px;
    background-color: #08D70D;
    border-radius: 2px;
    margin-bottom: 55px;
}

.step-one{

}

.step-two{

}

.step-three{

}

@media screen and (max-width: 375px) {
    .tn-process__modal .modal-dialog {
        max-width: 350px;
    }
}

@media screen and (max-width: 320px) {
    .tn-process__modal .modal-dialog {
        max-width: 300px;
    }
}