.tron-connection-error{
    /* position: relative; */
}

.tron-connection-error-close{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
}


.tron-error-title{
    text-align: center;
    padding: 30px 0 0 0;
}

.tron-login-error__header{
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
}

.tron-login-error__header img{
    width: 35%;
}

.tron-connection-error__body{
    text-align: center;
}

.tron-connection-error__body.install{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.tron-connection-error-button{
    width: 128px;
    height: 40px;
    color: white;
    background-color: #3E64ED;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
