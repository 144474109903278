.empty__row{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.empty__box{
    width: calc(90% / 3);
    height: 320px;
    border-radius: 20px;
    border: 1px dashed #36405A;
}

@media screen and (max-width: 1200px){
    .empty__box{
        height: 320px;
        border-radius: 20px;
        border: 1px dashed #36405A;
    }
}

@media screen and (max-width: 768px) {
    .empty__box{
        width: calc(90% / 3);
        height: 320px;
    }
}

/* @media screen and (max-width: 600px) {
    .empty__box{
        width: calc(90% / 2);
        height: 250px;
    }
} */

@media screen and (max-width: 600px) {
    .empty__box{
        width: calc(90% / 2);
        height: 250px;
    }

}

@media screen and (max-width: 425px) {
    .empty__box{
        width: calc(90% / 2);
        height: 210px;
    }

}

/* @media screen and (max-width: 375px) {
    .empty__box{
        width: calc(90% / 2);
        height: 210px;
    }

} */