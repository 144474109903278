:root {
    --fontColorDark: #9696df;
    --bgColorDark: #000000;
    --cardsBGColorDark: #84DFFF;
    --cardContentColorDark:  #0a6b66;
    --buttonColorDark: rgb(0, 255, 98);
    --buttonColorDarkHover: rgb(49, 206, 109);
    --borderColorDark: rgb(190, 31, 31);
    --modalBGColorDark: #0000ff;
}


.dark-palette{
    background-color: var(--bgColorDark);
}

.dark-palette .themBtn{
    background: var(--buttonColorDark);
    border: 1px solid #4C4C6D;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    max-width: 326px;
    text-align: center;
    border-radius: 16px;
    color: #fff;
    font-size: 16px;
    line-height: 56px;
}

.dark-palette .themBtn:hover{
    background: var(--buttonColorDarkHover);
    color: #fff;
}

.dark-palette .modal-content {
    background: unset;
    background-color: var(--modalBGColorDark);
}

.dark-palette .modal-title

.dark-palette .nftTitle h2 {
    color: var(--fontColorDark);
}

.dark-palette .yourNft {
    color: var(--fontColorDark);
}

.dark-palette .yourNft span, .dark-palette .desChain span, .dark-palette .modalSelectOptionsText, .dark-palette .selectedNft span, .dark-palette .nftFees span{
    color: var(--fontColorDark);
}

.dark-palette .seleDepat, .dark-palette .seleDestiSele, .dark-palette .modal-title, .light-palette .yourNft, .dark-palette .sendNftTit, .dark-palette .approveBtn{
    color: var(--fontColorDark);
}

.dark-palette .nft-image__container{
    background-color: var(--cardsBGColorDark);
}

.dark-palette .nft-content__container{
    background-color: var(--cardContentColorDark);
}

.dark-palette .nftSelectBox {
    border: 1px solid var(--borderColorDark);
}

.disabled .themBtn {
    background: linear-gradient(0deg, rgba(73, 82, 108, 0.1), rgba(73, 82, 108, 0.1));
    border: 1px solid #37405B;
    color: #5A6177;
    pointer-events: none;
}

