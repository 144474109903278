@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700&family=Inter:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap);
/* 
font-family: 'Inconsolata', monospace;
font-family: 'Inter', sans-serif;
*/

html,
body {
  font-family: "Inter", sans-serif;
}

body {
  color: rgb(149 164 191 / 80%);
  font-size: 16px;
  line-height: 160%;
}

.widgetBlack {
  color: black !important;
}
.widgetWhite {
  color: white !important;
}

.bridgeBody {
  min-height: 100vh;
  background-color: #1d212a;
  position: relative;
  z-index: 1;
}

ul {
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

h3 {
  font-size: 18px;
  margin: 0;
}

.navBrand:hover {
  cursor: pointer;
}

.HeaderArea {
  padding: 8px 45px;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
}

.navMenu.navbar-nav {
  margin-left: auto;
  overflow: hidden;
}

.account__box {
  width: 190px;
  height: 40px;
  background: #232833;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4532b5;
  color: white;
}

.account__box:hover {
  border: 1px solid #3e64ed;
}

.navMenu.navbar-nav .nav-link {
  margin-left: 7px;
  font-size: 15px;
  line-height: 126%;
  display: inline-flex;
  align-items: center;
  text-align: right;
  color: #f4f3f9;
  opacity: 0.9;
  /* max-width: fit-content; */
}

.navMenu.navbar-nav .nav-link.active,
.navMenu.navbar-nav .nav-link:hover {
  color: #f4f3f9;
  opacity: 1;
}

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

div#root:after {
  content: "";
  background-image: url(/static/media/body_art.e8214d72.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  width: 886px;
  height: 518px;
  left: 50%;
  transform: translate(-50%);
  top: 110px;
  position: absolute;
  z-index: -1;
}

footer {
  margin-top: auto;
}

.footContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px 45px 25px 45px;
  border: 1px solid #292f3f;
  /* margin-top: 250px; */
}

ul.socialLInks {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

ul.socialLInks li {
  margin-right: 6px;
}

a.socliLink img {
  width: 24px;
}

ul.socialLInks li img:hover {
  filter: brightness(100);
}

.footRight {
  display: flex;
}

.powerBy {
  margin-right: 98px;
}

.powerBy img {
  display: block;
  margin-left: 15px;
}

.priPoli {
  display: flex;
  align-items: center;
}

.priPoli a {
  font-size: 15px;
  line-height: 2.1;
  display: flex;
  align-items: center;
  text-align: right;
  color: #a3caa3;
  opacity: 0.5;
  margin-left: 22px;
}

.priPoli a:hover {
  opacity: 1;
}

.powerBy a {
  font-size: 15px;
  line-height: 129%;
  display: flex;
  align-items: center;
  color: rgb(163 179 202 / 50%);
  line-height: 32px;
}

.NftSelect {
  max-width: 533px;
  margin: 0 auto;
}

.nftContainer {
  margin-top: 68px;
}

.nftTitle h2 {
  font-weight: bold;
  font-size: 38px;
  line-height: 116%;
  text-align: center;
  color: #ffffff;
}

.nftTitle {
  margin-bottom: 27px;
}

.nftSlectArea {
  max-width: 326px;
  margin: 0 auto;
}

.nftSelectBox {
  position: relative;
  z-index: 1;
  background: linear-gradient(
    105.45deg,
    rgba(37, 42, 54, 0.72) 5.13%,
    rgba(37, 42, 54, 0) 138.1%
  );
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 16px;
  border: 1px solid #37405b;
  margin-bottom: 26px;
}

.selChain {
  padding: 22px 25px;
  position: relative;
  z-index: 1;
  color: #fff;
}

.selChain img {
  display: block;
  margin-right: 16px;
}

.selChain > div {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.selChain > div:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/arrow_down.ebeeda02.svg);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
}

.selChain > div:hover:after {
  filter: brightness(100);
  /* background-color: rgba(29, 33, 42, 0.10); */
}

span.chainArrow {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

span.chainArrow:hover {
  cursor: pointer;
}

/*.chainArrow img {
  height: 28px;
  width: 28px;
  background-color: #2c3240;
  border-radius: 6px;
  padding: 7px 5px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chainArrow img:hover {
  background-color: #297efe;
}*/

span.LineArrow img {
  display: block;
  width: 100%;
}

.themBtn {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  max-width: 326px;
  text-align: center;
  background: #3e64ed;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  line-height: 56px;
  border: 1px solid #3e64ed;
}

/* .green{
    background: #11992a;
    border: 1px solid #11992a;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    max-width: 326px;
    text-align: center;
    border-radius: 16px;
    color: #fff;
    font-size: 16px;
    line-height: 56px;
}
.green:hover{
    background: #349c47;
    color: #fff;
}

.red{
    background: #c91b1b;
    border: 1px solid #c91b1b;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    max-width: 326px;
    text-align: center;
    border-radius: 16px;
    color: #fff;
    font-size: 16px;
    line-height: 56px;
}
.red:hover{
    background: #eb3232;;
    color: #fff;
} */

.themBtn:hover {
  background: #174aff;
  color: #fff;
}

.disabled .themBtn {
  background: linear-gradient(
    0deg,
    rgba(73, 82, 108, 0.1),
    rgba(73, 82, 108, 0.1)
  );
  border: 1px solid #37405b;
  color: #5a6177;
  pointer-events: none;
}

.changeBtn.disabled {
  background: linear-gradient(
    0deg,
    rgba(73, 82, 108, 0.1),
    rgba(73, 82, 108, 0.1)
  );
  border: 1px solid #37405b;
  color: #5a6177;
  pointer-events: none;
}

.aboutNft {
  text-align: center;
  padding-top: 22px;
}

.videoLink,
.about_Nft {
  letter-spacing: 0.03em;
  color: #95a4bf;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 126%;
}

.videoLink:hover,
.about_Nft:hover {
  color: #95a4bf;
  opacity: 1;
}

.about_Nft img,
.videoLink img {
  display: block;
  margin-right: 5px;
}

.aboutNft a {
  margin-bottom: 9px;
}

.aboutNft a:hover {
  cursor: pointer;
}

.about__text {
  width: 100%;
  height: 300px;
  margin: 0 auto;
  overflow: auto;
  color: #fff;
}
.about__text p {
  padding: 0px 40px;
  padding: 0 60px 0 60px;
}
.sliderContainer {
  max-width: 1170px;
  margin: 0 auto;
  margin-top: 68px;
  margin-bottom: 80px;
}

.nfgSlidImg img {
  display: block;
  max-width: 100%;
}

.nftSlidNumber {
  max-width: 198px;
  margin: 0 0 9px 0;
  color: #ffffff;
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nftSlidImg {
  background: #292f3f;
  padding: 15px 18px;
}

.nftSlideBox {
  border-radius: 16px;
  overflow: hidden;
}

.nftAut a {
  color: #3e64ed;
  opacity: 0.8;
  display: inline-block;
  font-weight: 590;
  font-size: 14px;
  line-height: 126%;
}

.aleartBox {
  position: fixed;
  display: flex;
  align-items: center;
  font-size: 14px;
  z-index: 11;
  left: 50%;
  bottom: 25vh;
  transform: translate(-50%);
  background: linear-gradient(291.33deg, #181b23 67.06%, #412194 183.04%);
  border-radius: 16px;
  padding: 14px 54px 14px 16px;
  color: #fff;
}
.Close img {
  width: 18px;
}
span.closeBox {
  height: 20px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 14px;
  border-left: 1px solid #62718a;
  cursor: pointer;
}

.ChainModal .modal-dialog {
  max-width: 370px;
}

.modal-content {
  background: #232833;
  border: 1px solid #2d3342;
  background: linear-gradient(
    309.48deg,
    #232833 -3.65%,
    rgba(40, 46, 60, 1) 122.77%
  );
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
}
.modal-backdrop {
  background: #1d212a;
  opacity: 0.9;
}
.modal-header {
  border-bottom: 1px solid #2d3342;
  position: relative;
  z-index: 1;
  min-height: 70px;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: center;
}

.modal-title {
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: #fff;
}

.text-left.modal-header {
  justify-content: flex-start;
}

.CloseModal {
  position: absolute;
  z-index: 1;
  left: auto;
  right: 28px;
  cursor: pointer;
}

.CloseModal:hover img {
  filter: brightness(100);
}

.modal-body {
  padding: 0px 0 24px 0;
}

.searchChain {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 30px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.searchChain input {
  font-weight: 400;
  display: block;
  flex-grow: 1;
  border: 0;
  outline: none;
  background: rgba(98, 113, 138, 0.1);
  border-radius: 10px;
  height: 48px;
  padding: 0 36px 0 16px;
  color: #fff;
}

.searchChain button {
  position: absolute;
  right: 0;
  background: transparent;
  border: 0;
  outline: none;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  height: 48px;
  width: 30px;
  text-align: left;
}

.nftChainList {
  list-style: none;
  max-height: 364px;
  overflow: auto;
  border-bottom: 1px solid #2d3342;
}

.nftChainItem {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 30px;
  cursor: pointer;
}

.nftChainItem img {
  display: block;
  width: 28px;
  margin-right: 13px;
}

.nftChainItem:hover {
  background: rgba(73, 82, 108, 0.1);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  background: radial-gradient(
    96.34% 96.34% at 59.82% 3.66%,
    #4532b5 0%,
    #36405a 100%
  );
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: radial-gradient(
    96.34% 96.34% at 59.82% 3.66%,
    #4532b5 0%,
    #36405a 100%
  );
}

span.wallet {
  position: absolute;
  left: 28px;
}

.walletListBox {
  padding: 19px 30px 19px 30px;
  background: linear-gradient(
    309.48deg,
    #282e3c -3.65%,
    rgba(40, 46, 60, 0) 122.77%
  );
  border-bottom: 1px solid #2d3342;
  overflow-y: scroll;
}

.no-wallets {
  text-align: center;
}

.maiarModal {
  padding: 20px 30px;
  background: linear-gradient(
    309.48deg,
    #282e3c -3.65%,
    rgba(40, 46, 60, 0) 122.77%
  );
  border-bottom: 1px solid #2d3342;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.walletList {
  list-style: none;
  max-height: 336px;
}

.wllListItem {
  margin-bottom: 12px;
  height: 56px;
  /* width: 100px; */
  border: 1px solid rgba(111, 123, 165, 0.1);
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: #fff;
  cursor: pointer;
  display: flex;
}

.wllListItem .coming-chain {
  margin-left: auto;
}

.wllListItem div {
  display: flex;
}

.beacon img{
  width: 28px
}

.algo img {
  width: 28px;
  border-radius: 5px;
}

.wllListItem img {
  /* width: 100%; */
  display: block;
  margin-right: 13px;
}

.wllListItem:hover {
  background: rgba(73, 82, 108, 0.1);
  border: 1px solid #6f7ba5;
}

.wllListItem:last-child {
  margin: 0;
}

.nftListTop {
  display: flex;
  justify-content: space-between;
}

.nftTopRIght {
  display: flex;
  align-items: center;
}

.SearchDrop.dropdown .btn {
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  outline: none;
  box-shadow: none !important;
}

.SearchDrop.dropdown .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  width: 185px;
  background: rgba(98, 113, 138, 0.1);
  border-radius: 10px;
  transform: unset !important;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.SearchDrop.dropdown .dropdown-menu form {
  display: contents;
}

.SearchDrop.dropdown input {
  max-width: 100%;
  border: 0;
  background: transparent;
  outline: none;
  font-size: 14px;
  line-height: 140%;
  color: #62718a;
  padding-left: 16px;
  padding-right: 40px;
  color: rgb(255 255 255 / 80%);
  height: 38px;
  border-radius: 9px;
}

.SearchDrop.dropdown input:focus {
  outline: 1px solid #174aff;
}

.SearchDrop.dropdown input:-ms-input-placeholder {
  color: rgb(98 113 138 / 50%);
}

.SearchDrop.dropdown input::placeholder {
  color: rgb(98 113 138 / 50%);
}

.SearchDrop.dropdown form button {
  border: 0;
  outline: none;
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 0;
  width: 40px;
  padding: 0;
  background: transparent;
}

.SearchDrop.dropdown .btn:active {
  background: unset;
}

.SearchDrop.dropdown .dropdown-toggle::after {
  display: none;
}

.selectAll {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  height: 40px;
  min-width: 100px;
  padding: 5px 9px;
  color: #3e64ed;
  cursor: pointer;
  flex-wrap: nowrap;
}

.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 5px 11px;
  cursor: pointer;
  min-width: 40px;
  background: rgba(98, 113, 138, 0.1);
  margin-left: 8px;
  border-radius: 8px;
  margin: 0 2px;
}

.seleDestiSele {
  color: white;
}

.modalSelectOptionsText {
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accountBox {
  position: absolute;
  background: #262a36;
  top: 60px;
  right: 15px;
  z-index: 200;
}

.tooltip__wrapper {
  position: absolute;
  width: 150px;
  top: 40px;
  right: 40px;
}

.tooltip__container {
  position: relative;
}

.tooltip__container img {
  position: absolute;
  top: 0;
}

.tooltip__container div {
  position: absolute;
  top: 17px;
  left: 20px;
  font-size: 11.5px;
}

.yourNft--mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 18px 0;
  padding-right: 2px;
}

.yourNft__title {
  font-size: 16px;
  color: #62718a;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.yourNft__chain {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.yourNft__chain span:first-child {
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
}

.yourNft--mobile div {
  margin-right: 10px;
}

.yourNft,
.yourNft span {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
  height: 40px;
}

.yourNft span {
  margin-left: 8px;
}

.nft-box__wrapper {
  border-radius: 20px;
  -moz-border-border-radius: 20px;
  -webkit-border-radius: 20px;
  -webkit-appearance: none;
  appearance: none;
  margin: 1px 0px;
}

.yourNft span img {
  display: block;
  margin-right: 7px;
}

.singleNft {
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
}

.singleNft-selected {
  border: 1px solid #3e64ed !important;
}

.singleNft.missing {
  background: linear-gradient(
    335deg,
    rgba(41, 47, 63, 0.3) -0.71%,
    rgba(41, 47, 63, 0) 61.32%
  );
  border: 1px dashed #36405a;
  padding-top: 3px;
  padding-bottom: 138%;
  width: 224px;
}

.singleNft.missing:hover {
  cursor: unset;
}
.nftListBox {
  width: 100%;
}
.nftListBox > .row > div {
  margin-bottom: 24px;
}

.nftViewBtn {
  margin: 0 5px;
  min-width: 40px;
}

.nft--success {
  margin-bottom: 70px;
}

.reloadNfts {
  background: rgba(98, 113, 138, 0.1);
  box-shadow: 0px 1px 9px rgba(55, 63, 92, 0.12);
  border-radius: 8px;
  padding: 6px;
  cursor: pointer;
}

.reloadNfts img:last-child {
  margin: 0 0 0 8px !important;
}
.nftImage {
  cursor: pointer;
  width: 100%;
  background-color: #1e222d;
  display: flex;
  justify-content: center;
}
.nftImageBox {
  position: relative;
  display: flex;
  /* top: -30px; */
  overflow: hidden;
  cursor: pointer;
  /* max-height: 160px; */
}

.copyTokk {
  position: relative;
}

.success__copy {
  margin-left: 10px;
}

.copytoltip {
  position: absolute;
  top: 40px;
  left: 130px;
}

.copytoltip-tron {
  position: absolute;
  top: 40px;
  left: 120px;
}

.nft-sceleton-img {
  position: relative;
  display: flex;
  /* top: -30px; */
  overflow: hidden;
  cursor: pointer;
  height: 220px;
}

.selectNft {
  position: absolute;
  z-index: 3;
  left: 10px;
  top: 15px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.nftCont {
  background: #292f3f;
  padding: 6px 12px 20px;
  height: 90px;
  /* z-index: 10; */
}

.nftCont span {
  display: block;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

span.nftImage img {
  display: block;
  height: 100%;
  /* height: 200px;    opacity: 0; */
}

div.nft__image--loader {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.774);
  border-radius: 20px 20px 0px 0px;
}

span.nftName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.NFTInf {
  margin: 0 !important;
  cursor: pointer;
  transition: 0.1s ease all;
}

span.NFTInf:hover img {
  filter: brightness(100);
}

.info-icon__container {
  min-width: 24px;
}

.selectNft img {
  opacity: 0;
  visibility: hidden;
}

.nftSelect .selectNft img {
  opacity: 1;
  visibility: visible;
}

.nft_selectBox {
  background: #232833;
  border-radius: 20px;
  padding: 16px 0;
  padding-left: 25px;
  height: 100%;
  position: relative;
  z-index: 1;
}

.nftListBox {
  max-height: 680px;
  overflow: hidden auto;
  margin-right: -24px;
  margin-top: 26px;
  padding: 0px 9px;
}

.nftEmptyListBox {
  width: 100%;
  max-height: 680px;
  overflow: hidden;
  margin-right: -24px;
  margin-top: 26px;
  padding: 0px 9px;
}

.nftEmptyListBox > .row > div {
  margin-bottom: 24px;
}

.singleNft.nftSelect {
  outline: 1px solid #3e64ed;
}

.nft-sceleton.singleNft {
  position: absolute;
  top: 0;
  width: 90%;
  background: linear-gradient(
    242.44deg,
    #62718a -3.49%,
    #383f56 46.4%,
    #62718a 102.48%
  );
}

.nft-sceleton.nftName {
  height: 20px;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(
    242.44deg,
    #62718a -3.49%,
    #383f56 46.4%,
    #62718a 102.48%
  );
}

.nft-sceleton.nftNumber {
  height: 20px;
  width: 50%;

  border-radius: 10px;
  background: linear-gradient(
    242.44deg,
    #62718a -3.49%,
    #383f56 46.4%,
    #62718a 102.48%
  );
}

.nftCont span {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.nftImageBox:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #3e64ed;
  opacity: 0.8;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 600px) {
  .nftImageBox:hover:after {
    background-color: unset;
  }
}

.nftImageBox:hover:after {
  opacity: 0.8;
  visibility: visible;
}

.ListView {
  display: block;
  cursor: pointer;
  /* margin-left: 1px; */
}

.ListView-mob {
  display: block;
  /* background: rgba(98, 113, 138, 0.1); */
  margin-left: 2px;
  width: 20px !important;
}

.ListView:hover {
  background: rgba(98, 113, 138, 0.1);
  border-radius: 10px;
}

.NFTaccount {
  margin-top: 80px;
  margin-bottom: 80px;
}

.sendNftTit {
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 58px;
  margin-bottom: 2px;
}

.sendNftBox {
  background: linear-gradient(
    316.2deg,
    #232833 -4.13%,
    rgba(35, 40, 51, 0) 136.63%
  );
  box-shadow: 0px 4px 14px rgba(47, 61, 84, 0.05);
  border-radius: 20px;
  padding: 0px 16px 38px;
  height: 100%;
  min-height: 778px;
  display: flex;
  flex-direction: column;
}

.sendNftBox > form {
  display: contents;
}

.nftSendBtn {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.destiAddress {
  background: linear-gradient(
    309.48deg,
    #232833 -3.65%,
    rgba(35, 40, 51, 0) 122.77%
  );
  border-radius: 20px;
  padding: 12px 15px 16px;
  margin-bottom: 12px;
}

.desChain {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 12px;
}

.desChain span {
  /* cursor: pointer; */
  margin-left: 10px;
  color: #ffff;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  grid-gap: 6px;
  gap: 6px;
}
/* .desChain span:hover {
    background: rgba(98, 113, 138, 0.1);
    border-radius: 8px;
} */

.triangle {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.triangle img {
  padding: 6px;
}

.triangle:hover {
  background-color: rgba(55, 57, 61, 0.63);
}

.triangle:hover img {
  filter: brightness(10);
}

.desChain span img:nth-child(2) {
  margin-left: 4px;
}
.destiReload {
  display: flex;
  align-items: center;
  padding: 6px;
  background: rgba(98, 113, 138, 0.1);
  border-radius: 8px;
  cursor: pointer;
}

.destiReload img:last-child {
  margin: 0 0 0 7px !important;
}

.destiAddress input {
  background: #242936;
  border-radius: 10px;
  height: 42px;
  padding: 10px 12px;
  display: block;
  width: 100%;
  border: 1px solid #4235a3;
  color: #fff;
  outline: none;
}

.nftSelectList {
  background: linear-gradient(
    309.48deg,
    #232833 -3.65%,
    rgba(35, 40, 51, 0) 122.77%
  );
  border-radius: 20px;
}

.destiAddress input:-ms-input-placeholder {
  color: #62718a;
}

.destiAddress input::placeholder {
  color: #62718a;
}

.destiAddress input:focus {
  border: 1px solid #fff;
}

.selectedNft {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 16px 16px 12px;
}

.clearNft {
  margin-left: auto;
  border: 0;
  outline: none;
  background: transparent;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: #3e64ed;
  padding: 0;
}

.selectedNft span {
  color: #fff;
  margin-left: 3px;
}

ul.nftSelected {
  list-style: none;
  max-height: 220px;
  overflow: auto;
}

ul.nftList {
  list-style: none;
  overflow: auto;
}
.refreshnfts {
  margin-left: 6px;
}
.nftListed {
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 30px;
  color: #fff;
  position: relative;
}
.nftListed:hover {
  background: rgba(73, 82, 108, 0.1);
}
li.nftListed > img, li.nftListed > video {
  display: block;
  height: 32px;
  width: 32px;
  margin-right: 13px;
  border-radius: 7px;
}

.nftListed:hover {
  cursor: pointer;
}

.selectNftListed {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 13px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.nftSelecItem {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  color: #fff;
}
.nftSelecItem:hover {
  cursor: pointer;
}

li.nftSelecItem > img,
li.nftSelecItem > video {
  display: block;
  height: 32px;
  margin-right: 13px;
  border-radius: 4px;
}

li.nftSelecItem .Close {
  margin-left: auto;
}

.nftSelecItem:hover {
  background: rgba(73, 82, 108, 0.1);
}

li.nftSelecItem .Close:hover img {
  filter: brightness(100);
}

li.nftSelecItem .Close:hover {
  cursor: pointer;
}

.approValBox {
  margin-top: 12px;
  background: linear-gradient(
    309.48deg,
    #232833 -3.65%,
    rgba(35, 40, 51, 0) 122.77%
  );
  border-radius: 20px;
  margin-top: 12px;
  padding: 16px 16px 24px 16px;
}

.appInf {
  position: relative;
  z-index: 1;
}

.infText {
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 211px;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%);
  border: #2d3342 1px solid;
  border-radius: 6px;
  background: #262a36;
  font-size: 12px;
  line-height: 140%;
  padding: 12px 12px 20px;
  margin-bottom: 10px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
}

.approvTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  height: 28px;
  margin-bottom: 12px;
  color: #62718a;
}

.infText:after {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 100%;
  display: block;
  content: "";
  width: 15px;
  height: 15px;
  content: "";
  border: #2d3342 1px solid;
  background: #262a36;
  border-left: 0;
  border-top: 0;
  transform: translate(-50%) rotate(45deg);
  margin-top: -6px;
}

.appInf:hover .infText {
  opacity: 1;
  visibility: visible;
}

.approveBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.approveBtn input {
  display: none;
}

.approveBtn label {
  margin: 0;
  width: 56px;
  height: 31px;
  background: #49526c;
  border-radius: 56px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s ease all;
}

.checkCircle {
  position: absolute;
  width: 24px;
  height: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
}

.approveBtn input:checked + label {
  background: #08d70d;
}

.approveBtn input:checked + label .checkCircle {
  left: 27px;
}

.checkCircle:after {
  content: "";
  background-image: url(/static/media/CheckGray.816731c2.svg);
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s ease all;
}

.approveBtn input:checked + label .checkCircle:after {
  background-image: url(/static/media/CheckGreen.99e97ac0.svg);
}

.nftFees {
  margin: 12px 0;
  background: linear-gradient(
    309.48deg,
    #232833 -3.65%,
    rgba(35, 40, 51, 0) 122.77%
  );
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  color: #62718a;
  height: 62px;
  padding: 16px;
}

.nftFees span {
  color: #fff;
}

.modal-dialog {
  margin-top: 15vh;
}

.NftDetails .modal-dialog {
  max-width: 630px;
}

.nftDetailBox {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.nftDetImg {
  flex-shrink: 0;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  padding: 0 15px 0 30px;
}

.nftDetImg img, .nftDetImg video {
  height: 60%;
  border-radius: 16px;
}

.nftDetIg {
  width: 50%;
  padding: 0 30px 0 15px;
  max-height: 297px;
  overflow: auto;
}

.nftInfBox {
  background: #272c39;
  border: 1px solid #2d3342;
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 9px;
}

.nftInfBox label {
  color: #62718a;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 6px 0;
}

.nftInfBox p {
  font-size: 14px;
  line-height: 140%;
  color: #fff;
  margin: 0;
  word-break: break-all;
}

.nftInfBox:last-child {
  margin-bottom: 0;
}

.nftInfDesc.nftInfBox {
  padding-right: 5px;
}

.nftDetailBox {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.nftDetImg {
  flex-shrink: 0;
  width: 50%;
  padding: 0 15px 0 30px;
}

.nftDetIg {
  width: 50%;
  padding: 0 30px 0 15px;
  max-height: 297px;
  overflow: auto;
}

.nftInfBox {
  background: #272c39;
  border: 1px solid #2d3342;
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 9px;
}

.nftInfBox label {
  color: #62718a;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 6px 0;
}

.nftInfBox p {
  font-size: 14px;
  line-height: 140%;
  color: #fff;
  margin: 0;
}

.nftInfBox:last-child {
  margin-bottom: 0;
}

.nftInfDesc.nftInfBox {
  padding-right: 5px;
}

.nftSuccessMod .modal-dialog {
  max-width: 570px;
}

.nftSuccessMod .modal-header {
  border: 0;
  margin-top: 26px;
  margin-bottom: 6px;
}

.nftSuccessMod .modal-content {
  padding: 0 60px;
}

.nftSuccessMod .CloseModal {
  right: 0;
}

.successBox {
  background: linear-gradient(
    309.48deg,
    #282e3c -3.65%,
    rgba(40, 46, 60, 0) 122.77%
  );
  border-radius: 6px;
  padding: 8px 30px;
  margin-bottom: 12px;
}

.nftSuccessMod .nftSelectList {
  background: linear-gradient(
    309.48deg,
    #282e3c -3.65%,
    rgba(40, 46, 60, 0) 122.77%
  );
  border-radius: 6px;
}

.sucesList label {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 126%;
  color: #62718a;
}

.sucesList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  color: #fff;
}

.colBlue {
  color: #297eff !important;
  cursor: pointer;
}

.SentFrom img {
  margin-right: 5px;
  width: 32px;
}

span.statComp {
  color: #10b67a;
}

.sucesList span {
  display: flex;
  align-items: center;
}

span.copyTokk img {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}

span.copyTokk:hover {
  cursor: pointer;
}

span.statComp img {
  margin-right: 5px;
}

.nftSuccessMod .nftSelecItem {
  padding: 6px 30px;
}

.seleDepatSelec img{
  width: 28px;
}

.nftSelecItem__name{
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bluTextBtn {
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  margin-left: auto;
  margin-right: 2px;
  text-align: center;
  font-size: 14px;
  line-height: 140%;
  color: #3763ff;
  background: rgba(98, 113, 138, 0.1);
  border-radius: 6px;
  padding: 2px 6px;
  min-width: 80px;
}

.bluTextBtn:hover {
  background: rgba(98, 113, 138, 0.1);
  box-shadow: 0px 1px 9px rgba(55, 63, 92, 0.12);
}

.nftSuccessMod .selectedNft {
  padding-left: 30px;
  padding-right: 30px;
}

.nftWornTop h3 {
  color: #fff;
  margin: 22px 0 14px;
}

.nftWornTop p {
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
}

.nftWornTop {
  margin-bottom: 65px;
}

.switchingAcc img {
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}

.switching:hover {
  cursor: pointer;
}

.nftDetContList {
  margin-bottom: 8px;
  background: #272c39;
  border-radius: 6px;
  padding: 8px 12px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 10px;
}

/*     background: #272C39;
    border: 1px solid #2D3342;
    padding: 8px 12px;
    border-radius: 6px;
    margin-bottom: 9px; */

.details {
  border-radius: 6px;
  font-size: 14px;
  line-height: 160%;
  word-break: break-all;
  color: #ffffff;
}
.switchingAcc {
  margin-bottom: -24px;
}
.switchingAcc p {
  color: #62718a;
  margin-top: 9px;
  margin-bottom: 0;
}

.wrongNFT {
  padding-bottom: 24px;
}

.nftListView span.NFTInf {
  margin-left: auto !important;
  display: block;
}

.nftListView span.Close {
  margin-left: 16px;
}

.nftListView .nftSelecItem {
  height: 58px;
}

.nftListView .nftSelected {
  max-height: 100%;
  overflow: auto;
}

.nftListBox.nftListView {
  margin-right: 0px !important;
  margin-right: -25px !important;
}
/* 
 .nft_selectBox.nftListView {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
} */

.nftListView .nftListTop {
  padding: 0 24px;
}

.nft_selectBox.nftListView .nftListBox {
  flex-grow: 1;
}

.transTableCont {
  background: linear-gradient(
    316.2deg,
    #232833 -4.13%,
    rgba(35, 40, 51, 0) 136.63%
  );
  box-shadow: 0px 4px 14px rgba(47, 61, 84, 0.05);
  border-radius: 20px;
  padding: 15px 0 23px;
}

.transTitle {
  color: #fff;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}

table.transferTable.table {
  margin: 0;
  border: 0;
  overflow: auto;
}

.transferTable.table thead th,
.transferTable.table tr td {
  border: 0;
  color: #62718a;
  font-weight: 600;
  font-size: 16px;
  line-height: 126%;
  height: 56px;
  padding: 0;
  vertical-align: middle;
  padding: 14px 0;
}

.transferTable.table tbody tr td {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  padding: 12px 0;
}

.transferTable.table thead {
  background: linear-gradient(
    309.48deg,
    #232833 -3.65%,
    rgba(35, 40, 51, 0) 122.77%
  );
}

.transferTable.table img.nftImg {
  width: 32px;
}

.transferTable.table tbody tr td:last-child {
  padding-right: 10px;
}

.transferTable.table tbody tr td:first-child {
  padding-left: 16px;
}

span.transFail,
span.transPend,
span.statComp {
  display: flex;
  align-items: center;
}

span.transFail img,
span.transPend img,
span.statComp img {
  width: 16px;
  margin-right: 7px !important;
  flex-shrink: 0;
}

.transferTableBox {
  max-height: 310px;
  overflow: auto;
}

.nonftContainer {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  /* height: 100%; */
  display: flex;
}

.nonftAcc {
  position: absolute;
  left: 47.3%;
  top: 200px;
  width: 505px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transform: translate(-50%);
  margin-left: -9%;
  padding-left: 20%;
  height: 415px;
  font-size: 16px;
  line-height: 160%;
  color: rgb(255 255 255 / 80%);
  background-image: url(/static/media/noNftBG.8b5da6d6.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.nonftAcc img.nonft {
  margin-bottom: 35px;
  display: block;
}

.nonftAcc h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 49px;
  color: #fff;
}

.switching {
  background: #3e64ed;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  line-height: 126%;
  padding: 18px 45px;
}

.maiarConnectBtn {
  background: #3e64ed;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  line-height: 126%;
  padding: 18px 45px;
  margin: 15px;
}

.maiarSubtitle {
  padding-bottom: 10px;
  color: white;
}

.maiarConnectBtn:hover {
  background: #174aff;
  color: #fff;
}

.switching:hover {
  background: #174aff;
  color: #fff;
}

.nonftAcc .switching {
  margin-top: 9px;
}

.desAddress {
  position: relative;
  z-index: 1;
}

span.invalid {
  background: linear-gradient(273.47deg, #1d212a 6.6%, #4b041e 221.21%);
  border-radius: 10px;
  display: none;
  align-items: center;
  padding: 2px 7px;
  margin-top: 6px;
}

span.invalid img {
  display: inline-block;
  margin-right: 3px;
}

.ComentBox {
  display: flex;
  align-items: flex-start;
  padding: 10px;
}

.ComentBox img {
  display: block;
  width: 27px;
  margin-right: 10px;
}

.ComentBox p {
  margin: 0;
  font-size: 14px;
  line-height: 140%;
  color: #fff;
}

.navbar-light .navbar-toggler span.navbar-toggler-icon {
  display: none;
}

.navbar-light .navbar-toggler:after {
  content: url(/static/media/Hambar.b3c7081c.svg);
}

.collecSlideCont .slideItem {
  padding: 0 15px;
}

.collecSlideCont {
  margin-top: 78px;
}

.collecSlideCont.container {
  max-width: 1227px;
}

/* .navMenu.navbar-nav .nftConnect.nav-link {
    border: 1px solid #4532B5;
    background: #1D212A;
    border-radius: 8px;
    padding: 8px 20px;
} */

/* .navMenu.navbar-nav .nftConnect.nav-link:hover{
    border: 1px solid #297EFF;
    background: none;
} */

.navMenu.navbar-nav .nftConnect.nav-link img {
  margin-left: 6px;
  margin-top: -2px;
}

.accountBox {
  /* position: absolute; */
  /* right: 0; */
  /* top: 60px; */
  /* background: #262A36; */
  border: 1px solid #2d3342;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 16px 24px 24px;
  width: 310px;
}

.accountTit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 126%;
  color: #fff;
  height: 28px;
}

.accountTit .CloseModal {
  left: auto;
  right: 0;
}

.accountBox p {
  font-size: 16px;
  line-height: 28px;
  color: #62718a;
  margin: 12px 0;
}

.nftLink {
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 25px;
  width: 100%;
}

.nftLink img {
  margin-right: 9px;
}

.nftLink img:last-child {
  margin-left: 8px;
}

.accountBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.changeBtn {
  font-size: 14px;
  line-height: 40px;
  background: #3e64ed;
  border-radius: 10px;
  color: #fff;
  padding: 0 14px;
  margin: 0 2px;
  height: 40px;
  display: inherit;
  cursor: pointer;
  border: none;
}

a.disconBtn {
  font-size: 14px;
  line-height: 40px;
  border-radius: 10px;
  color: #fff;
  padding: 0px 30px;
  height: 40px;
  display: inherit;
  background: linear-gradient(
    0deg,
    rgba(73, 82, 108, 0.1),
    rgba(73, 82, 108, 0.1)
  );
  border: 1px solid #37405b;
  pointer-events: none;
}

.disconBtn:hover {
  background: #3e64ed;
}

.changeBtn:hover {
  background: #174aff;
  color: #fff;
}

span.wallet img {
  width: 22px;
}

.nftListBox .col-lg-4.col-md-4.col-sm-6.col-6 {
  padding-right: 12px;
  padding-left: 12px;
}

.nftSuccessMod .modal-title img {
  width: 23px;
  position: relative;
  top: -3px;
}

.transTitle h3 {
  font-size: 18px;
  line-height: 22px;
}

span.transFail {
  color: #c23165;
}

span.transPend {
  color: #c058ff;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  top: 50px;
  transition: calc(0.5);
  color: #3e64ed;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.approve-loader__container__text {
  position: absolute;
  top: 46%;
  left: 42%;
  color: white;
}

.approve-loader,
.approve-loader:after {
  /* z-index: 200; */
  border-radius: 50%;
  width: 20em;
  height: 20em;
}
.approve-loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(255, 255, 255, 0.2);
  border-right: 0.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
  border-left: 0.5em solid #ffffff;
  transform: translateZ(0);
  animation: load8 2.1s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.approve-modal .modal-dialog .modal-content {
  background: none !important;
  border: none !important;
  /* position: absolute !important; */
  /* top: 20% !important; */
}

.changeNetwork-loader,
.changeNetwork-loader:before,
.changeNetwork-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
}
.changeNetwork-loader {
  color: #3e64ed;
  font-size: 4px;
  margin: 20px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.changeNetwork-loader:before,
.changeNetwork-loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.changeNetwork-loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.changeNetwork-loader:after {
  left: 3.5em;
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.tron-PopUp__list {
  width: calc(100% - 100px);
  margin: 0 auto 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.tron-PopUp__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tron-PopUp__icon {
  margin: 30px 0 20px 0;
}

.link__items {
  display: flex;
  justify-content: center;
  position: relative;
}

.video-responsive iframe {
  width: 100%;
  height: 640px;
}

.tron-modal__copyIcon {
  margin: 0 4px;
}
.claimsbut div {
  color: #3763ff !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 8px;
  background: rgba(98, 113, 138, 0.1);
  border-radius: 6px;
  cursor: pointer;
}

.claimsbut div:hover {
  filter: brightness(1.5);
}

.noimagenft {
  height: 100px;
}
.tron-modal__link {
  margin: 0 auto;
  width: 260px;
  height: 40px;
  background: #50505077;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyTronTT {
  position: absolute;
  bottom: 15px;
  right: -50px;
}

.copyTronTTc {
  position: absolute;
  bottom: 15px;
  right: -60px;
  z-index: 200;
}

.copy-icon__box {
  position: relative;
  z-index: 1;
  width: 24px;
  display: flex;
  justify-content: flex-end;
}

.copytooltip {
  position: absolute;
  z-index: 400;
  bottom: 10px;
  right: -55px;
}

.copytooltip--v {
  position: absolute;
  z-index: 5;
  bottom: 10px;
  right: -65px;
}

.coming__chain {
  width: 96px;
  height: 20px;
  background: #2b313e;
  border-radius: 4px;
  font-size: 12px;
  color: #3e64ed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chain__off {
  width: 50px;
  height: 20px;
  background: #2b313e;
  border-radius: 4px;
  font-size: 12px;
  color: #ed3e3e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.walletalgotitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 12px;
  color: #ffffff;
}

.imgcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.claimablealgornadnftss {
  display: flex;
  justify-content: center;
}
.claimablealgornadnftss tr {
  width: 100%;
  min-width: 100%;
}
.claimablealgornadnftss td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.claimablealgornadnftss td img {
  width: 180px;
}

.claimablealgornadnftss .claimsbut div {
  width: 120px;
  margin: 8px 0px;
  background-color: #3763ff !important;
  color: #fff !important;
}

.get-featured {
  margin: 100px auto 100px auto;
  cursor: pointer;
  max-width: 200px;
  display: flex;
  justify-content: center;
}

.get-featured img {
  margin: 0 8px;
}

.get-featured:hover {
  filter: brightness(2.5);
  color: white;
}
/* widget */

.widget div#root:after {
  display: none;
}
.widget .aleartBox {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 931px) {
  .navBrand img {
    width: 250px !important;
    max-width: unset;
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 768px) {
  .singleNft.missing {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    margin-top: 20vh;
  }
  .video-responsive iframe {
    height: 280px;
  }

  .navbar-collapse {
  }
  .navMenu.navbar-nav .nav-link:first-child {
    border-radius: 18px 18px 0px 0px;
  }
  .navMenu.navbar-nav .nav-link:last-child {
    border-radius: 0px 0px 18px 18px;
  }
  .desChain {
    justify-content: space-between;
  }

  .aleartBox {
    bottom: 10vh;
  }

  .account__box {
    width: 250px;
  }

  .navMenu {
    width: calc(100% - 30px);
    position: fixed;
    z-index: 200;
    opacity: 1;
  }
}

@media screen and (max-width: 320) {
  .approve-loader__container__text {
    left: 38%;
  }

  .info-icon__container {
    margin-left: 10px;
  }
}

@media screen and (max-width: 375) {
  .successBox {
    padding: 8px 20px !important;
  }
  .approve-loader__container__text {
    left: 39%;
  }
}

@media screen and (max-width: 400) {
  .approve-loader__container__text {
    left: 39% !important;
  }
}

@media screen and (min-width: 600px) {
  .yourNft--mobile {
    display: none;
  }
}

@media (min-width: 1200px) {

    .container {
        max-width: 1200px;
    }
   
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    
}

@media only screen and (max-width: 1199px) {
    
}

@media only screen and (max-width: 1024px) {
    
    .infText {
        right: -32px;
        left: auto;
        transform: unset;
    }

    .infText:after {
        left: auto;
        transform: rotate(45deg);
        right: 35px;
    }

}


@media only screen and (min-width: 768px) and (max-width: 991px) {

}

@media only screen and (max-width: 991px) {

}
    
@media only screen and (min-width: 426px) and (max-width: 767px) {
   
}

@media only screen and (min-width: 860px) {
    .mobileOnly {
        display: none !important;
    }
  
}
@media(max-width: 766px) {
    .sendNftTit {
        margin-top: -50px;
    }
    .navMenu{
        /* background-color: #232833;
        padding:  0 24px; */
        /* overflow: hidden; */
        border-radius: 20px;
        border: 1px solid #2D3342;
    }

    .navMenu .navMenu__link{
        width: 100%;
        background-color: #232833;
        padding: 23px;
        margin: 0 !important;
        
    }

    .nftConnect{
        
        display: flex;
        justify-content: center;
    }

    .navMenu__link:hover{
        background-color: #292E3B;
    }

    .nftConnect{
        padding-bottom: 24px;
    }
}
@media only screen and (max-width: 860px) {

    .mobileOnly.seleNftMob {
        display: flex;
        align-items: center;
        color: #62718A;
    }

    .mobileOnly.seleNftMob span {
        color: #297EFF;
        display: inline-block;
        margin-left: 5px;
    }

    .desktopOnly {
        display: none !important;
    }

    .col-lg-4.col-md-4.col-sm-6.col-6 {
        padding-right: 8px;
        padding-left: 8px;
    }

    .nft_selectBox {
        padding-right: 16px;
        padding-left: 16px;
    }

    .nftListBox> .row> div {
        margin-bottom: 12px;
    }

  

    .selectNft {
        left: 12px;
        top: 12px;
    }

    .nftListBox> .row {
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: space-between;
    }
    
    span.nftImage img {
        width: 100%;
        height: auto;
    }
    .nftImageBox {
        width: 100%;
    }
    .span.nftImage img {
        width: 100%;
    }
    .nftName {
        width: 100% !important;
    }
    .nftListBox .col-lg-4.col-md-4.col-sm-6.col-6 {
        padding-right: 0px;
        padding-left: 0px;
        max-width: 48%;
    }
    .nftCont span :first-child{
        width: 100%;
    }
    .nftName {
        margin-top: 0px !important;
    }
    .nftCont  {
        padding-bottom: 0px;
        height: 78px;
    }
    .singleNft {
    }

    .desChain span {
        /* margin-left: 20px; */
        padding: 0px 20px;
        justify-content: space-between;
    }
    .desChain span img:nth-child(2) {
        margin-top: 3px !important;
        margin-left: 18px !important;
        margin-right: 2px !important;
    }
    .desChain span img {
        border-radius: 50%;
    }
    span.NFTInf img {
        width: 24px !important;
    }
    .destiReload {
        padding: 0px 18px;
    }
    .NFTInf {
        width: 24px !important;
    }
    .nftListBox {
        margin-right: -12px;
        padding-right: 12px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .aleartBox {
        width: 90vw;
    }

    /* .modal {
        padding: 0 !important;
    } */

    .nftWorng .modal-dialog,
    .ChainModal .modal-dialog,
    .NftDetails .modal-dialog {
        max-width: 343px;
        margin: 0 auto;
    }

    .nftDetailBox {
        display: block;
        height: 80vh;
        overflow: auto;
        max-height: 660px;
    }

    .nftDetailBox> div {
        width: 100%;
        padding: 0 20px;
        overflow: unset;
    }

    .nftDetImg {
        margin-bottom: 8px;
    }

    /* .modal-dialog {
        margin: 30px auto 0 auto;
    } */
   
    /* .nftSuccessMod .modal-dialog {
       transform: scale(0.8);
    } */

    .nftSuccessMod .modal-content {
        padding: 0 20px;
        /* border-radius: 0; */
    }

    .modal-header {
        min-height: 56px;
    }

    /* .nftSuccessMod .modal-header {
        margin-top: 20px;
    } */

    .transferTable.table thead th, .transferTable.table tr td {
        padding: 12px !important;
        white-space: nowrap;
    }

    .transferTable.table tbody tr td:first-child {
        min-width: 64px;
    }

    table.transferTable.table {
        min-width: 1020px;
    }

    .container {
        max-width: 100%;
    }

    .footLeft {
        margin-bottom: 30px;
    }

    .footRight {
        flex-direction: column;
        text-align: left;
        justify-content: flex-start;
    }

    .footContainer {
        flex-direction: column;
    }

    .priPoli a {
        margin: 0 22px 0 0;
    }

    .footRight> div {
        min-height: 46px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .powerBy {
        margin-bottom: 14px;
    }

    .nftTitle h2 {
        font-size: 28px;
    }

    .nftContainer {
        margin-top: 60px;
    }

    .HeaderArea {
        padding: 8px 16px;
    }

    .navbar {
        padding: 0 !important;
        align-items: center;
    }
    .navbar-light .navbar-toggler:after{
        margin-top: 21px;
        top: -13px;
        right: 0px;
        position: absolute;
    }
    .nftTopRIght {
        position: relative;
    }

    .nftListTop {
        flex-wrap: wrap;
    }

    .seleNftMob {
        width: 40%;
    }

    .nftTopRIght {
        width: 60%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
    }

    .mobSearch {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 20px;
    }
    .mobSearch img {
        min-width: 16px;
        margin-left: -4px;
    }
    .mobSearch form {
        display: contents;
    }

    .mobSearch input {
        display: block;
        flex-grow: 1;
        border: 0;
        background: rgba(98, 113, 138, 0.1);
        border-radius: 10px;
        height: 40px;
        font-size: 16px;
        line-height: 160%;
        padding: 0 40px 0 16px;
        color: #62718A;
        outline: none;
    }

    .mobSearch input:-ms-input-placeholder {
        color: rgb(98 113 138 / 50%);
    }

    .mobSearch input::placeholder {
        color: rgb(98 113 138 / 50%);
    }

    .mobSearch button {
        position: absolute;
        right: 0;
        background: transparent;
        border: 0;
        outline: none;
        width: 40px;
        height: 40px;
    }

    .mobSearch input:focus {
        outline: 1px solid #174AFF;
    }

    .selectAll {
        background: rgba(98, 113, 138, 0.1);
        border-radius: 8px;
    }

    .nft_selectBox {
        height: auto;
    }

    .nftSendBtn {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .nftSendBtn a.themBtn {
        width: 100%;
        max-width: unset;
    }

    .nftselectedtop {
        justify-content: space-between !important;
    }

    .nftselectedtop .clearNft {
        margin: 0;
    }

    .nftselectedtop span.mobileOnly {
        font-weight: 600;
        font-size: 16px;
        line-height: 126%;
        color: #62718A;
    }

    .mtm50 {
        margin-top: 50px;
    }

    .sendNftBox {
        height: auto;
        min-height: unset;
    }

    .collecSlideCont .slideItem {
        padding: 0 11px;
    }
        
    .yourNft.seleNftMob.mobileOnly span {
        height: auto;
    }

    .navMenu.navbar-nav .nav-link {
        /* margin-left: 0;
        max-width: fit-content; */
    }

    .infText {
        right: -15px;
    }

    .infText:after {
        right: 18px;
    }


} /* max-width: 767px */
       
@media only screen and (max-width: 775px) {
    .accountBox {
        left: 50%;
        transform: translate(-50%, 0);
    }
    .singleNft{
            /* max-height: 160px !important; */
    }
}
    
@media only screen and (min-width: 320px) and (max-width: 425px) {
	
}    

@media only screen and (max-width: 480px) {

    .collecSlideCont.container {
        width: 250px;
    }

    .collecSlideCont .slick-list {
        overflow: visible;
    }
    
    .nonftContainer .row{
        grid-gap: 10px;
        gap: 10px;
    }
	
}
      
@media only screen and (max-width: 375px) {

}
   

.empty__row{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.empty__box{
    width: calc(90% / 3);
    height: 320px;
    border-radius: 20px;
    border: 1px dashed #36405A;
}

@media screen and (max-width: 1200px){
    .empty__box{
        height: 320px;
        border-radius: 20px;
        border: 1px dashed #36405A;
    }
}

@media screen and (max-width: 768px) {
    .empty__box{
        width: calc(90% / 3);
        height: 320px;
    }
}

/* @media screen and (max-width: 600px) {
    .empty__box{
        width: calc(90% / 2);
        height: 250px;
    }
} */

@media screen and (max-width: 600px) {
    .empty__box{
        width: calc(90% / 2);
        height: 250px;
    }

}

@media screen and (max-width: 425px) {
    .empty__box{
        width: calc(90% / 2);
        height: 210px;
    }

}

/* @media screen and (max-width: 375px) {
    .empty__box{
        width: calc(90% / 2);
        height: 210px;
    }

} */
.nft-list__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  grid-gap: 20px;
  gap: 20px;
}

.nft-box__wrapper{
  position: relative;
}


.nft-box__container {
    margin-top: 1px;
    width: 224px;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.64px;
    /* border-radius: 2pt; */
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    overflow: hidden;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid transparent;
}

.brocken-url{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  color: #62718A;
}

.nftListed .brocken-url{
  flex-direction: row;
}

.nftListed .brocken-url img{
  width: 32px;
  height: 32px;
}

.brocken-url-selected{
  width: 32px;
  padding: 1px;
  border: 1px solid rgba(128, 128, 128, 0.411);
  border-radius: 5px;
}

.brocken-url__msg{
  text-align: center;
}

.preload__container{
    width: 224px;
    width: 100%;
    height: 345px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-border-radius: 20px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-appearance: none;
    appearance: none;
}

.preload__image{
    background-color: #1E222D;
    flex-grow: 4;
}
.preload-cont {
  pointer-events: none;
}
.preload__content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #292F3F;
    flex-grow: 1;
    pointer-events: none;
}
.preload-content-container {
  pointer-events: none;
}
.preload__name{
    height: 10px;
    width: 80%;
    background-color: #62718A;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-border-radius: 5px;
    -webkit-appearance: none;
    appearance: none;
}

.preload__number{
    height: 10px;
    width: 50%;
    background-color: #62718A;
    border-radius: 5px;
}

.image__wrapper img, .image__wrapper video {
    max-width: 80%;
    max-height: 240px;
    border-radius: 5px;
}

.nft-image__container {
    background-color: #1e222d;
    height: 244px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
}


.image__wrapper {
  display: flex;
  justify-content: center;
}

.nft-content__container {
  background-color: #292f3f;
  height: 100px;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.nft-box__container--selected {
    margin-top: 1px;
    width: 224px;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.64px;
    border-radius: 20px;
    -moz-border-border-radius: 20px;
    -webkit-border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    /* outline: solid 1px #3E64ED; */
    border: 1px solid #3E64ED;
    -webkit-appearance: none;
    appearance: none;
}
.nft-box__container:hover{
  border: 1px solid #3e64ed8f;

}



.selected-radio {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 26px;
  height: 26px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.705);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.radio__container img{
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.705);
}

.nft-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nft-number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.missing{
  height: 100%;
}

@media screen and (max-width: 768px) {
  .nft-list__wrapper{
    grid-gap: 8px;
    gap: 8px;
  }
  .nft-box__wrapper{
    margin-left: 2px;
    width: calc(100% / 3.33);
  }
  .nft-box__container{
    max-width: 100%;

  }
  .nft-box__container--selected{
    max-width: 100%;

  }
  .missing-nft-box__wrapper{
    margin-left: 2px;
    width: calc(100% / 3.33);
  }
}

@media screen and (max-width: 600px){
  .nft-list__wrapper{
    grid-gap: 10px;
    gap: 10px;
  }
  .nft-box__wrapper {
    width: 47%;
  }
  .nft-box__container{
    width: 100%;
  }
  .nft-box__container--selected{
    width: 100%;
  }
  .missing-nft-box__wrapper{
    width: 47%;
  }
  .nft-box__wrapper:hover{
    outline: unset;
  }
}

@media screen and (max-width: 375px){
  .nft-list__wrapper{
    grid-gap: 10px;
    gap: 10px;
  }
  .nft-box__container{
    width: 90%;
    max-height: 240px;
  }
  .nft-box__container--selected{
    width: 90%;
    max-height: 240px;
  }
  .missing-nft-box__wrapper{
    width: 45%;

  }
  .preload__container{
    width: calc(100% - 15px);
    height: 240px !important;
  }
}

@media screen and (max-width: 425px) {
  .image__wrapper{
    max-height: calc(100% - 30px);
  }
  .nft-image__container{
    max-height: 170px;
  }
  .radio__container{
    
  }
  .preload__container{
    height: 270px;
  }
}

/* .check-mark__container{
    position: absolute;
    top: 7%;
    left: 7%;
    background-color: white;
}

.check-mark__box{
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-color: white;
    border-radius: 25%;
}

.check-mark__img{
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    /* visibility: hidden; */

.new-chain{
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 15.12px;
    background: linear-gradient(100.8deg, rgba(21, 255, 27, 0.66) -59.7%, rgba(46, 139, 196, 0.66) 75.86%, rgba(55, 99, 255, 0.66) 123.09%);
    padding: 4px 6px;
    border-radius: 4px;
}

.coming-chain{
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 15.12px;
    background: linear-gradient(103.87deg, rgba(8, 78, 215, 0.66) -74.95%, rgba(143, 55, 255, 0.66) 122.06%);
    padding: 4px 6px;
    border-radius: 4px; 
}

.nftSelected__name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sucesList{
    position: relative;
}

.tooltip-icon{
    position: absolute;
    right: -56px;
    top: -45px;
    cursor: pointer;
}

.tooltip-icon:hover{
    cursor: pointer;
}

.success__copy{
    cursor: pointer;
}

@media screen and (max-width: 375) {
    .nft--success .nftSelecItem{
        padding: 6px 20px !important ;
    }
}

/* .txn-hash{
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 320px) {
    .txn-hash{
        max-width: 100px;
    }
} */
.ts-modal{
    background-color: rgba(0, 0, 0, 0.596);
}

.ts-modal .h4{
    font-size: 16px;
}

.ts-modal .modal-content{
    transform: scale(0.9);
    font: 16px;
    line-height: 25.6px;
}

.close-ts-modal{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
}

.close-ts-modal:hover{
    filter: brightness(2.75);
}

.technical-support__body{
    display: flex;
    padding: 0 10px 55px 10px;
    flex-direction: column;
    align-items: center;
    
}

.ts-nft__image{
    max-width: calc(100% / 3);
    margin-top: 22px;
}

.ts-nft__image img{
    width: 100%;
    border-radius: 1rem;
}

.ts-nft__info{
    margin: 6px 0;
}

.ns-message{
    text-align: center;
    margin-bottom: 18px;
}

.ts-button{
    color: #3E64ED;
    width: 187px;
    height: 40px;
    border-radius: 8px;
    filter: brightness(1.3);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ts-button:hover{
    width: 187px;
    height: 40px;
    border-radius: 8px;
    background: #232833;
    filter: brightness(1.5);
}

.ts-button a :hover{
    color: inherit;
}
.widget .sendNftBox {
  background: unset;
  box-shadow: unset;
}

.widget .nft_selectBox {
  background: unset;
}

.widget .destiAddress {
  background: unset;
}

.widget .nftSelectList {
  background: unset;
}

.widget .approValBox {
  background: unset;
}

.widget .nftFees {
  background: unset;
}

.widget .nftSelectBox {
  background: unset;
}

.widget .destiAddress input {
  background: unset;
}

/*!! FONT SIZE */
/* !! SM */
.font-size-sm {
  font-size: 12px !important;
}

.font-size-sm h3 {
  font-size: 16px;
}

.font-size-sm .yourNft,
.font-size-sm .yourNft span {
  font-size: 14px !important;
}

.font-size-sm .clearNft {
  font-size: 14px !important;
}

.font-size-sm .nft-content__container {
  font-size: 12px !important;
}

.font-size-sm .themBtn {
  font-size: 14px !important;
}

.font-size-sm .modal-title {
  font-size: 16px !important;
}

.font-size-sm .nftInfBox label {
  font-size: 12px !important;
}

.font-size-sm .nftInfBox p {
  font-size: 12px !important;
}
/* !! XL */
.font-size-xl {
  font-size: 18px !important;
}

.font-size-xl h3 {
  font-size: 20px !important;
}

.font-size-xl .yourNft,
.font-size-xl .yourNft span {
  font-size: 20px !important;
}

.font-size-xl .clearNft {
  font-size: 18px !important;
}

.font-size-xl .nft-content__container {
  font-size: 16px !important;
}

.font-size-xl .themBtn {
  font-size: 18px !important;
}

.font-size-xl .modal-title {
  font-size: 20px !important;
}

.font-size-xl .nftInfBox label {
  font-size: 16px !important;
}

.font-size-xl .nftInfBox p {
  font-size: 16px !important;
}

:root {
    --fontColorDark: #9696df;
    --bgColorDark: #000000;
    --cardsBGColorDark: #84DFFF;
    --cardContentColorDark:  #0a6b66;
    --buttonColorDark: rgb(0, 255, 98);
    --buttonColorDarkHover: rgb(49, 206, 109);
    --borderColorDark: rgb(190, 31, 31);
    --modalBGColorDark: #0000ff;
}


.dark-palette{
    background-color: #000000;
    background-color: var(--bgColorDark);
}

.dark-palette .themBtn{
    background: rgb(0, 255, 98);
    background: var(--buttonColorDark);
    border: 1px solid #4C4C6D;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    max-width: 326px;
    text-align: center;
    border-radius: 16px;
    color: #fff;
    font-size: 16px;
    line-height: 56px;
}

.dark-palette .themBtn:hover{
    background: rgb(49, 206, 109);
    background: var(--buttonColorDarkHover);
    color: #fff;
}

.dark-palette .modal-content {
    background: unset;
    background-color: #0000ff;
    background-color: var(--modalBGColorDark);
}

.dark-palette .modal-title

.dark-palette .nftTitle h2 {
    color: #9696df;
    color: var(--fontColorDark);
}

.dark-palette .yourNft {
    color: #9696df;
    color: var(--fontColorDark);
}

.dark-palette .yourNft span, .dark-palette .desChain span, .dark-palette .modalSelectOptionsText, .dark-palette .selectedNft span, .dark-palette .nftFees span{
    color: #9696df;
    color: var(--fontColorDark);
}

.dark-palette .seleDepat, .dark-palette .seleDestiSele, .dark-palette .modal-title, .light-palette .yourNft, .dark-palette .sendNftTit, .dark-palette .approveBtn{
    color: #9696df;
    color: var(--fontColorDark);
}

.dark-palette .nft-image__container{
    background-color: #84DFFF;
    background-color: var(--cardsBGColorDark);
}

.dark-palette .nft-content__container{
    background-color: #0a6b66;
    background-color: var(--cardContentColorDark);
}

.dark-palette .nftSelectBox {
    border: 1px solid rgb(190, 31, 31);
    border: 1px solid var(--borderColorDark);
}

.disabled .themBtn {
    background: linear-gradient(0deg, rgba(73, 82, 108, 0.1), rgba(73, 82, 108, 0.1));
    border: 1px solid #37405B;
    color: #5A6177;
    pointer-events: none;
}


:root {
    --fontColorLight: #ffffff;
    --bgColorLight: #ffe760;
    --cardsBGColorLight: #00ff2a;
    --cardContentColorLight:  #0b8520;
    --buttonColorLight: rgb(255, 20, 20);
    --buttonColorLightHover: rgb(204, 121, 121);
    --borderColorLight: rgb(204, 24, 24);
    --modalBGColorLight: #00eeff;

}



.light-palette{
    background-color: #ffe760;
    background-color: var(--bgColorLight);
}

.light-palette .themBtn{
    background: rgb(255, 20, 20);
    background: var(--buttonColorLight);
    border: 1px solid rgb(255, 20, 20);
    border: 1px solid var(--buttonColorLight);
    display: inline-block;
    width: 100%;
    cursor: pointer;
    max-width: 326px;
    text-align: center;
    border-radius: 16px;
    color: #fff;
    font-size: 16px;
    line-height: 56px;
}

.light-palette .modal-content{
    background: unset;
    background: #00eeff;
    background: var(--modalBGColorLight);
}

.light-palette .themBtn:hover{
    background: rgb(204, 121, 121);
    background: var(--buttonColorLightHover) ;
    color: #fff;
}

.light-palette .nftTitle h2 {
    color: #ffffff;
    color: var(--fontColorLight);
}

.light-palette .yourNft{
    color: #ffffff;
    color: var(--fontColorLight);
}

.light-palette .yourNft span, .light-palette .desChain span, .light-palette .selectedNft span, .light-palette .nftFees span{
    color: #ffffff;
    color: var(--fontColorLight);
}



.light-palette .seleDepat, .light-palette .modalSelectOptionsText, .light-palette .modal-title, .light-palette .seleDestiSele, .light-palette .yourNft, .light-palette .sendNftTit, .light-palette .approveBtn{
    color: #ffffff;
    color: var(--fontColorLight);
}

.light-palette .nft-image__container{
    background-color: #00ff2a;
    background-color: var(--cardsBGColorLight);
}

.light-palette .nft-content__container{
    background-color: #0b8520;
    background-color: var(--cardContentColorLight);
}

.light-palette .nftSelectBox {
    border: 1px solid rgb(204, 24, 24);
    border: 1px solid var(--borderColorLight);
}

.disabled .themBtn {
    background: linear-gradient(0deg, rgba(73, 82, 108, 0.1), rgba(73, 82, 108, 0.1));
    border: 1px solid #37405B;
    color: #5A6177;
    pointer-events: none;
}



.transfer-loader-modal{
    background-color: rgba(0, 0, 0, 0.596);
}

.transfer-loader-modal .h4{
    font-size: 16px;
}

.transfer-loader__animation{
    padding: 60px 40px 20px 40px;
}

.transfer-loader__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 50px 20px;
}

.transfer-loader__title{
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: white;
    padding-bottom: 28px;
}

.transfer-loader__text{
    text-align: center;
    padding-bottom: 30px;
}

.transfer-loader__sub{
    padding-bottom: 30px;
}

/* Animation */
.center {
    height: 5vh;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .wave {
    width: 5px;
    height: 60px;
    background: linear-gradient(45deg, cyan, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  
  @media screen and (min-width: 576px) {
    .transfer-loader-modal .modal-sm{
        max-width:  370px;
    }
  }

  @media screen and (max-width: 576px) {
      .transfer-loader-modal .modal-content{
          transform: scale(0.9);
      }
  }
.tron-connection-error{
    /* position: relative; */
}

.tron-connection-error-close{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
}


.tron-error-title{
    text-align: center;
    padding: 30px 0 0 0;
}

.tron-login-error__header{
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
}

.tron-login-error__header img{
    width: 35%;
}

.tron-connection-error__body{
    text-align: center;
}

.tron-connection-error__body.install{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
}

.tron-connection-error-button{
    width: 128px;
    height: 40px;
    color: white;
    background-color: #3E64ED;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.get-featured-modal{

}

.get-featured-modal .modal-dialog{
    max-width: 630px !important;
    margin-top: 25vh;
}

.ger-featured__body{
    padding: 0 60px 26px 60px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
}

.get-featured-action-list{
    padding-bottom: 26px;
}

.get-featured-action-list li{
    margin: 20px 0;
}

.follow-us{
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    align-items:  center;
}

.follow-us__title{
    padding-bottom: 20px;
}

.follow-us__buttons{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 20px;
    gap: 20px;
    padding-bottom: 30px;
    justify-content: center;
}

.follow-us__btn{
    background-color: #3E64ED;
    height: 40px;
    width: 244px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    grid-gap: 13px;
    gap: 13px;
    cursor: pointer;
    color: white;
}

.follow-us__btn--disabled{
    height: 40px;
    width: 244px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    grid-gap: 13px;
    gap: 13px;
    cursor: pointer;
    background: linear-gradient(0deg, rgba(73, 82, 108, 0.1), rgba(73, 82, 108, 0.1));
    border: 1px solid #37405B;
    color: #5A6177;
    pointer-events: none;
}

.follow-us__btn:hover{
    color: inherit;
}

@media screen and (max-width: 600px) {
    .follow-us__btn{
        width: 200px;
    }

    .ger-featured__body{
        padding: 0 30px 26px 30px;
       
    }
}

@media screen and (max-width: 425px){
    .ger-featured__body{
        padding: 0 15px 26px 15px;
        font-size: 14px;
    }
    .follow-us__title{
        padding-bottom: 10px;
    }
    .get-featured-action-list li{
        margin: 10px 0;
    }
    .get-featured__title{
        padding-top: 10px;
    }
}
.tn-process__modal{
    color: white;
}

.tn-process__modal .modal-dialog {
    max-width: 370px;
    margin: 30vh auto 0 auto;
}

.tn-process__header{

}

.tn-process__title{
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
}

.tn-process__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 60px; */
}

.tn-process__icon{
    min-height: 60px;
    min-width: 60px;
    margin: 30px 0 50px 0;
}

.tn-process__icon img{
    width: 100%;
}

.txt-icon{
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tn-process__message{
    font-size: 18px;
    font-weight: 4600;
    line-height: 21.78px;
    padding-bottom: 40px;
}

.tn-process__loader{
    display: flex;
    grid-gap: 3.87px;
    gap: 3.87px;
}

.process-loader--grey{
    width: 96px;
    height: 6px;
    background-color: #3A415A;
    border-radius: 2px;
    margin-bottom: 55px;
    position: relative;
}

.process-loader--green{
    position: absolute;
    height: 6px;
    background-color: #08D70D;
    border-radius: 2px;
    margin-bottom: 55px;
}

.step-one{

}

.step-two{

}

.step-three{

}

@media screen and (max-width: 375px) {
    .tn-process__modal .modal-dialog {
        max-width: 350px;
    }
}

@media screen and (max-width: 320px) {
    .tn-process__modal .modal-dialog {
        max-width: 300px;
    }
}
.success-modal .modal-dialog{
    max-width: 570px;
    min-height: 500px;
}

.success-modal .modal-content{
    padding: 0 70px;
}

.success-modal .modal-header{
    padding-top: 40px;
}

.success-info-list{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.success-info-box{
    background: linear-gradient(309.48deg, #282E3C -3.65%, rgba(40, 46, 60, 0) 122.77%);
    border-radius: 6px;
    padding: 20px 30px;
}

.success-modal-close{
    position: absolute;
    top: 40px;
    right: 73px;
    cursor: pointer;
    z-index: 20;
}

.success-modal-close:hover{
    filter: brightness(5);
}

.view-txn-btn{
    background: #62718A1A;
    padding: 0 4px;
    border-radius: 6px;
    color: #3763FF;
}

.claim-btn{
    color: white;
    background-color: #3763FF;
    padding: 0 4px;
    border-radius: 6px;
}

.success-buttons{
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    font-size: 14px;
}

.success-button.claim-btn{
    cursor: pointer;
}

.success-info-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
}

.success-info-item span{
    color: white;
    margin-left: 6px;
    display: flex;
    align-items: center;
}

.success-hash img{
    cursor: pointer;
}

.success-hash img:hover{
    filter: brightness(5);
}

.info-item-chain{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: white;
    display: flex;
    align-items: center;
}

.info-item-chain img{
    margin-right: 6px;
    width: 28px;
}

.success-info-box label{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
}

.success-nft-info{
    min-height: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.success-hash{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #297EFF;
    display: flex;
}

.success-hash img{
    margin-left: 12px;
}

.transferred-nft{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 40px;
}

.success-nft-info__wrapper{
    width: 100%;
    padding: 10px 0;
}

.nft-image-name{
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.txn-status-pending{
    color: rgb(226, 195, 21);
}

.txn-status{
    min-width: 70px;
}

.nft-image-name div{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.transferred-nft img{
    height: 32px;
    border-radius: 4px;
}

.transferred-nft-name{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.transferred-nft-name__txt{
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.txn-failed{
    display: flex;
    align-items: center;
}

.txn-failed img {
    width: 20px;
    margin-right: 5px;
}

@media screen and (max-width: 500px) {
    .success-modal .modal-content{
        padding: 0 10px;
    }

    .success-info-box{
        padding: 10px 10px;
    }

    .success-modal .modal-header{
        padding-top: 30px;
    }

    .success-modal-close{
        top: 30px;
        right: 10px;
    }
}
