
.nftSelected__name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sucesList{
    position: relative;
}

.tooltip-icon{
    position: absolute;
    right: -56px;
    top: -45px;
    cursor: pointer;
}

.tooltip-icon:hover{
    cursor: pointer;
}

.success__copy{
    cursor: pointer;
}

@media screen and (max-width: 375) {
    .nft--success .nftSelecItem{
        padding: 6px 20px !important ;
    }
}

/* .txn-hash{
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 320px) {
    .txn-hash{
        max-width: 100px;
    }
} */